// import GetImagePixel from 'components/GetImagePixel'
import VideoEditor from 'components/VideoEditor/VideoEditor'
import React from 'react'

// const url = 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'

const Homepage = () => {
  
  return (
    <section className='py-2 px-2 flex flex-col justify-center items-center h-screen '>
      
      {/* <GetImagePixel /> */}

      <VideoEditor />
    </section>
  )
}

export default Homepage