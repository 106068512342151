const { default: Homepage } = require("pages/Homepage");
const { default: SelectImagePixel } = require("pages/SelectImagePixel");
const { createHashRouter } = require("react-router-dom");

export const router = createHashRouter([
  {
    path: "/",
    element: <Homepage />
  },
  {
    path: "/image/:url",
    element: <SelectImagePixel />
  }
])