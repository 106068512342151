import React, { useEffect, useRef, useState } from 'react'

const DrawLine = ({ onChange = () => {}, values, containerRef, active=false, disabled=false, drawStatus='draw' }) => {
  const [lines, setLines] = useState({ start: null, stop: null})
  const [status, setDraw] = useState(false)
  const divRef = useRef()
  const canvasRef = useRef()

  const __getPoint = (pageX, pageY) => {
    const div = containerRef || divRef.current
    const x = pageX - div.offsetLeft
    const y = pageY - div.offsetTop
    return { x, y } 
  }

  useEffect(() => {
    if(values && values.hasOwnProperty('start') && values.hasOwnProperty("stop")){
      setLines(values)
    }
  }, [values])

  useEffect(() => {
    if(lines.start && lines.stop){
      const { start, stop } = lines
      const canvas = canvasRef.current
      const ctx = canvas.getContext('2d')
      const div = containerRef || divRef.current

      canvas.height = div.clientHeight
      canvas.width = div.clientWidth

      ctx.clearRect(0, 0, canvas.width, canvas.height)
      const { x: startX, y: startY } = start
      const { x:stopX, y:stopY } = stop
      

      ctx.beginPath()
      ctx.moveTo(startX, startY)
      ctx.lineTo(stopX, stopY)

      if(active) ctx.strokeStyle = 'orange'
      else ctx.strokeStyle = '#ff0000'
      ctx.stroke()
      ctx.closePath()
    }
  }, [active, containerRef, lines])

  const __onMouseDown = (e) => {
    if(disabled) return;
    setDraw(true)
    const point = __getPoint(e.pageX, e.pageY)
    setLines((prev) => ({ ...prev, start: point }))
  }

  const __onMouseDownCapture = (e) => {
    if(!!status){
      const point = __getPoint(e.pageX, e.pageY)
      setLines(prev => ({...prev, stop: point }))
    }
  }

  const __onMouseUp = () => { 
    setDraw(false) 
    onChange(lines)
    if(drawStatus === 'remove') {
      const div = containerRef || divRef.current
      const canvas = canvasRef.current
      const ctx = canvas.getContext('2d')
      canvas.height = div.clientHeight
      canvas.width = div.clientWidth
      ctx.clearRect(0, 0, canvas.width, canvas.height)
    }

  }

  return (
    <div className='h-full w-full flex flex-col justify-center items-center'>
      <div 
        onMouseDown={__onMouseDown} 
        onMouseMove={__onMouseDownCapture} 
        onMouseUp={__onMouseUp} 
        ref={divRef} 
        className='cursor-crosshair h-full w-full'
      >
        <canvas ref={canvasRef} className='w-full h-full'></canvas>
      </div>
    </div>
  )
}

export default DrawLine