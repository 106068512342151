import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { CiTrash } from 'react-icons/ci'
import { twMerge } from 'tailwind-merge'
import { fixedTo } from 'utils/fixedTo'

const ImageForm = ({ refPoint, setRefPoint, setActiveRef, lines, setLines, __scaleFactor, onSubmit }) => {
  const [config, setConfig] = useState({ url: "https://drone-discharge-backend.wscada.net/velocities/post_data", no_of_division: "12", streamflow: "topdown" })
  
  const __inputHandle = (e) => {
    const { name, value } = e.target
    if(name === 'no_of_division' && isNaN(value)) return;
    setConfig((prev) => ({ ...prev, [name]: value }))
  }

  const __removeRefPoint = (indx, name) => {
    if(name === 'crop') setRefPoint(prev => prev.filter((_, __indx) => __indx !== indx))
    if(name === 'line') setLines(prev => prev.filter((_, __indx) => __indx !== indx))
  }

  const __onChange = (e, indx, name) => { 
    const { value } = e.target
    if(name === 'refpoint'){
      const latlng = value.replace(/\s/g, '').split(',').slice(0, 2)
      return setRefPoint(prev => prev.map((item, __indx) => {
        if(__indx === indx) { item.latlng = latlng }
        return item;
      }))
    }   

    if(name === 'linepoint'){
      setLines((prev) => {
        return prev.map((item, ind) => {
          if(ind === indx) item.distance = (isNaN(value)) ? item.distance || "" :  value
          return item;
        });
      })
    }

  }

  const pointWithDistance = (data) => {
   const { widthScaleFactor, heightScaleFactor } = __scaleFactor()

    return data.map((item) => {
      const { start, stop, distance } = item
      let x1 = fixedTo(start.x * widthScaleFactor)
      let y1 = fixedTo(start.y * heightScaleFactor)
      let x2 = fixedTo(stop.x * widthScaleFactor)
      let y2 = fixedTo(stop.y * heightScaleFactor)

      return { a: [x1, y1], b:[x2, y2], distance }
    })
  }

  const __onSumbmit = (e) => {
    e.preventDefault()
    if(config.url === '' || config.no_of_division === '') return toast.error("post url and no of division required")
    if(!Array.isArray(lines) || !Array.isArray(refPoint)) return toast.error("Distance point or Refpoint must be in array")
    if(lines.length === 0 && refPoint.length === 0) return toast.error("Must be required geo corrdinates point or draw distance")
    let type = 'unknown'
    let linePixel = null
    if(lines.length > 0){
      type = 'linepoint'
      linePixel = pointWithDistance(lines)
      
    } else if(refPoint.length > 0) {
      type = 'refpoint'
      const check = refPoint.every(({ latlng }) => Array.isArray(latlng) && latlng.length === 2)
      if(!check) return toast.error('Use Correct Latitude and Logitude')
    }
    const { url, no_of_division, streamflow } = config
    const formData = { type, lines: linePixel, ref: refPoint, no_of_division, streamflow }
    onSubmit(formData, url)
    
  }

  return (
    <div>
    <form method='post' onSubmit={__onSumbmit} className='w-full border px-2 py-2 flex flex-col gap-2 flex-1'>
      <div className='flex flex-row gap-2 justify-between items-center'>
        <div className='flex-grow'>
          <InputField name='url' value={config.url} onChange={__inputHandle} placeholder='Post url required'  required />
        </div>
        <InputField name='no_of_division' value={config.no_of_division} onChange={__inputHandle} placeholder='No of division'  required />
        <select value={config.streamflow} onChange={__inputHandle} name="streamflow" className='border px-2 py-2 text-sm bg-white outline-none'>
          <option value="buttomup">Buttom Up</option>
          <option value="topdown">Top Down</option>
        </select>
      </div>

      <div className='h-[80px] overflow-auto'>
        <div className='grid md:grid-cols-4 gap-2'>
          {Array.isArray(refPoint) && refPoint.map((point, indx) => {
            return (
              <InputField 
                key={indx} 
                value={point.latlng.join(',')} 
                onClick={() => setActiveRef(indx)}
                onChange={(e) => __onChange(e, indx, 'refpoint')} 
                placeholder={`lng,lat`} 
                __remove={() => __removeRefPoint(indx, 'crop')}
              />
              )
          })}
          {Array.isArray(lines) && lines.map((line, indx) => {
            return (
              <InputField 
                key={indx} 
                value={line.distance || ''} 
                onClick={() => setActiveRef(indx)}
                onChange={(e) => __onChange(e, indx, 'linepoint')} 
                placeholder={`in (meter)`} 
                __remove={() => __removeRefPoint(indx, 'line')}
                required={true}
              />
              )
          })}
        </div>
      </div>
      <button className='border-none px-2 py-1.5 text-sm text-white bg-blue-500 rounded-md hover:opacity-75 w-[50%] mx-auto '>Save</button>
    </form>

    </div>
  )
}

export default ImageForm

const InputField = ({ type, __remove, className, ...inputProps}) => {
  return (
    <div className='h-full text-sm text-slate-700 border flex flex-row items-center gap-1 px-2 py-1.5'>
      <input type={type || 'text'} className={twMerge("w-full outline-none", className)} {...inputProps} />
      {__remove && <CiTrash className='text-lg text-red-500 cursor-pointer' onClick={__remove} />}
    </div>
  )
}