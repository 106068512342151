import axios from 'axios';
import { BASE_URL } from 'config';
import React, { useCallback, useEffect, useState } from 'react'
import { FcImageFile, FcVlc } from "react-icons/fc";
import { Link } from 'react-router-dom';

const VideoList = ({ __onClick }) => {
  const [videos, setVideos] = useState([])
  const [imageList, setImageList] = useState([])

  const __getVideoList = useCallback(async() => {
    try {
      const url = `${BASE_URL}/api/video-list`
      const url2 = `${BASE_URL}/api/image-list`
      const [req, req1] = await Promise.all([axios.get(url), axios.get(url2)])
      if(req.status === 200){ 
        setVideos(req.data)
        setImageList(req1.data)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    __getVideoList()
  }, [__getVideoList])


  return (
    <div className='bg-slate-100'>
      <div className='grid md:grid-cols-2 gap-2'>
        <div>
          <h2 className='text-base font-bold text-slate-600 border-b py-1 px-2'>FTP Video List: ({Array.isArray(videos) ? videos.length : 0})</h2>
          <div>
            {Array.isArray(videos) && videos.map((item, indx) => {
              return (
                <div 
                  key={indx}
                  className='py-2 px-3 text-sm even:bg-slate-200 capitalize flex flex-row items-center justify-start gap-1 cursor-pointer hover:bg-slate-300'
                  onClick={() => __onClick(item.url)}
                >
                  <FcVlc className='text-lg' />
                  <span>{item.filename}</span>
                </div>
              )
            })}
          </div>
        </div>
        <div>
        <h2 className='text-base font-bold text-slate-600 border-b py-1 px-2'>Trim Video List: ({Array.isArray(videos) ? videos.length : 0})</h2>
          <div>
            {Array.isArray(imageList) && imageList.map((item, indx) => {
              const path = `/image/${item.filename}`
              return (
                <Link
                  to={path} 
                  key={indx}
                  className='py-2 px-3 text-sm even:bg-slate-200 capitalize flex flex-row items-center justify-start gap-1 cursor-pointer hover:bg-slate-300'
                >
                  <FcImageFile className='text-lg' />
                  <span>{item.filename}</span>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
      
     
    </div>
  )
}

export default VideoList