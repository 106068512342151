import { Slider } from 'antd'
import Video from 'components/VideoPlayer/Video'
import React, { useState } from 'react'
import Timer from './Timer'
import { IoCut } from "react-icons/io5";
import VideoList from 'components/VideoList/VideoList'
import { BASE_URL } from 'config'
import axios from 'axios';
import Spinner from 'components/Spinner/Spinner';
import { useNavigate } from 'react-router-dom';


const VideoEditor = () => {
  const navigate = useNavigate()
  const [videoUrl, setVideoUrl] = useState(null)
  const [sliderValues, setSliderValues] = useState([0, 100])
  const [duration, setDuration] = useState(null)
  const [startEnd, setStartEnd] = useState([0, 0])
  const [loading, setLoading] = useState(false)
  const [output, setOutput] = useState('')

  const __trimVideo = async() => {
    try {
      if(!output || output === '') { alert('Output name is required'); return;}
      if(!videoUrl) { alert('Please select the video url'); return }

      const obj = {
        filename: videoUrl.replace(`${BASE_URL}/api/`, ''),
        start_time: startEnd[0],
        end_time: startEnd[1],
        output: output
      }
      setLoading(true)
      const req = await axios.post(`${BASE_URL}/api/trim`, obj)
      if(req.status === 200){
        // console.log(req.data)
        navigate(`/image/${req.data.output_image}`)

      }
      setLoading(false)      
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }


  return (
    <section className='w-full'>
        <Spinner className="" text="Please Wait !" isLoading={loading} />
        <div className='mx-auto max-w-[90%] py-2 px-2 grid grid-cols-6 gap-2'>
          <div className='col-span-4 flex flex-col gap-2'>
            <div className='border relative min-h-[500px]'>
              {!videoUrl && <div className='absolute bg-slate-500 bg-opacity-50 text-white w-full h-full z-40 text-xl flex flex-col justify-center items-center'>Select Video From FTP List</div>}
              {videoUrl && <Video src={videoUrl} setDuration={setDuration} setStartEnd={setStartEnd} range={sliderValues} />}
            </div>
            <div className='border text-sm py-2'>
            <div className='text-center'><Timer duration={duration} /></div>
            <div className='flex flex-row justify-center items-center gap-1 px-2'>
              <Timer duration={startEnd[0]} />
              <div className='flex-grow'>
                <Slider
                  disabled={!videoUrl}
                  value={sliderValues}
                  range={true}
                  onChange={(val) => setSliderValues(val)}
                  tooltip={{ formatter: null }}
                />
              </div>
              <Timer duration={startEnd[1]} />
            </div>
            <div className='flex flex-row justify-center items-center gap-1'>
              <input className='border outline-none px-1 py-1' placeholder='Output filename' value={output} onChange={(e) => setOutput(e.target.value)} disabled={!videoUrl} />
              <button 
                disabled={!videoUrl}
                onClick={__trimVideo}
                className='flex flex-row justify-center items-center gap-2 border px-3 py-1.5 rounded-md bg-blue-500 text-white text-sm hover:opacity-65 disabled:bg-opacity-50'
              >
                <IoCut />
                <span>Trim</span>
              </button>
            </div>
              
            </div>
          </div>
          <div className='col-span-2 border'>
            <VideoList 
              __onClick={(url) => { 
                  setVideoUrl(`${BASE_URL}/api/${url}`) 
                  setSliderValues([0, 100])
                }}
            />
          </div>
        </div>
      
    </section>
  )
}

export default VideoEditor