export const calculateTime = (duration) => {
  let hh, mm, ss
  if(duration > 3600) {
    hh = Math.floor(duration / 60*60)
    mm = Math.floor((duration - (hh * 3600))/60)
    ss = Math.floor((duration - (hh * 3600) - (mm * 60)))
    if(hh < 10) hh = `0${hh}`
    if(mm < 10) mm = `0${mm}`
    if(ss < 10) ss = `0${ss}`
    return `${hh}:${mm}:${ss}`
    
  } if(duration < 3600){
    mm = Math.floor(duration/60)
    ss = Math.floor(duration % 60)
    if(mm < 10) mm = `0${mm}`
    if(ss < 10) ss = `0${ss}`
    return `${mm}:${ss}`
  }

  return '00:00'
}