import moment from 'moment'
import React from 'react'

const DischargeResult = ({ data }) => {
  return (
    <div className='border'>
      <div className='text-sm py-2 px-2 bg-blue-500 text-white'>Time: {moment(data ? data.timestamp : new Date()).format("DD MMM YYYY || hh:mm a")} (Discharge Result)</div>
      <div className='overflow-auto flex flex-row'>
        {data && Array.isArray(data.velocities) && data.velocities.map((v, indx) => {
          return (
            <div className='flex flex-col gap-2 justify-start items-center border-r text-sm' key={indx}>
              <div className='border-b w-full text-center px-2 py-2'>V{indx} </div>
              <div className='px-2 py-2'>{v}</div>
            </div>
          )
        })}  
 
      </div>
    </div>
  )
}

export default DischargeResult