import { BASE_URL } from 'config'
import DrawLine from 'pages/DrawLine'
import React, { useRef, useState } from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import ImageForm from './ImageForm'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast'
import Points from './Points'
import SelectButtons from './SelectButtons'
import { twMerge } from 'tailwind-merge'
import DischargeResult from './DischargeResult'


const fixedTo = (val) => (Math.floor(val * 100) / 100)
const ImageContainer = ({ url }) => {
  const params = useParams()
  const [container, setContainer] = useState(null)
  const [result, setResult] = useState(null)
  const [refPointStatus, setRefPointStatus] = useState(null)
  const [refPoint, setRefPoint] = useState([])
  const imgRef = useRef(null)
  const [crop, setCrop] = useState(null)
  const [pixels, setPixels] = useState(null)
  const [lines, setLines] = useState([])
  const [activeRef, setActiveRef] = useState(0)
  const resultRef = useRef()
  const lensRef = useRef()


  const __onSumbmit = async(formData, url) => {
    try {
      const image = '/trim-video/' + params.url;
      const video = image.replace('jpg', 'mp4')
      const data = { ...formData, pixels, image, video }

      const req = await toast.promise(axios.post(url, data), {
        loading: "Processing",
        success: "Discharged calculated successfully",
        error: "Something is wrong !"
      })
      if(req.status === 200){
        console.log(req.data)
        setResult(req.data)
      }

    } catch (error) {
      console.log(error)
    }
  }

  const __scaleFactor = () => {
    const img = imgRef.current

    const naturalWidth = img.naturalWidth
    const naturalHeight = img.naturalHeight

    const actualWidth = img.width
    const actualHeight = img.height
    
    const widthScaleFactor = naturalWidth / actualWidth
    const heightScaleFactor = naturalHeight / actualHeight
    // console.log(naturalWidth, naturalHeight)
    return { widthScaleFactor, heightScaleFactor }
  }

  const __onImageClick = (e) => {
    if(refPointStatus !== 'crop' || result) return;
    const x = e.pageX - container.offsetLeft
    const y = e.pageY - container.offsetTop
    const { widthScaleFactor, heightScaleFactor } = __scaleFactor()
    
    const x1 = fixedTo(x * widthScaleFactor)
    const y1 = fixedTo(y * heightScaleFactor)

    setRefPoint((prev) => [...prev, { pixels: [x1, y1], latlng: []}])
  }

  const __onDragEnd = () => {
    const { x, y, height, width } = crop;
    const { widthScaleFactor, heightScaleFactor } = __scaleFactor()
    
    const x1 = fixedTo(x * widthScaleFactor)
    const x2 = fixedTo((x + width) * widthScaleFactor)
    const y1 = fixedTo(y * heightScaleFactor)
    const y2 = fixedTo((y + height) * heightScaleFactor)
 
    setPixels({ a: [x1, y1], b: [x2, y1], c: [x2, y2], d: [x1, y2] })
    // console.log(crop)
  }

  const __onBtnChange = (name) => {
    if(name === 'crop') { setLines([]) }
    else { setRefPoint([]) }
    if(name !== refPointStatus) setRefPointStatus(name)
    else setRefPointStatus(null)
  }

  const __clearAll = () => {
    setPixels(null)
    setActiveRef(0)
    setResult(null)
    setRefPoint([])
    setLines([])
    setCrop(null)
    setRefPointStatus(null)
  }

  const __onMouseMove = (e) => {
    // console.log(e)
    if(pixels === null || !refPointStatus || result) return;
    const lens = lensRef.current
    const img = imgRef.current

    let x = e.pageX - container.offsetLeft 
    let y = e.pageY - container.offsetTop
    x = x - (lens.offsetWidth/2)
    y = y - (lens.offsetHeight/2)
    let imgWidth = img.clientWidth - lens.offsetWidth
    let imgHeight = img.clientHeight -lens.offsetHeight 

    if( x > imgWidth) { x = imgWidth }
    if(x < 0) { x = 0 }
    if( y > imgHeight) { x = imgHeight  }
    if(y < 0) { y = 0 }

    let cx = resultRef.current.offsetWidth / lens.offsetWidth
    let cy = resultRef.current.offsetHeight / lens.offsetHeight

    // console.log(cx, cy, x, y, resultRef.current.offsetHeight, lens.offsetHeight)
    resultRef.current.style.backgroundSize = `${img.width * cx}px ${img.height * cy}px`
    resultRef.current.style.backgroundPosition = `-${x*cx}px -${y * cy}px`
    lens.style = `top: ${y}px; left: ${x}px`;

  }

  return (
    <section className='w-full flex flex-col justify-center items-center gap-2'>
        {pixels && refPointStatus && !result && <div className='border absolute top-1 left-1 h-[150px] xl:h-[300px] w-[150px] xl:w-[300px] flex flex-row justify-center items-center'>
          <div ref={resultRef} className='w-full h-full' style={{ backgroundImage: `url('${url}')` }} />
          <div className='absolute h-[10px] w-[10px] rounded-full bg-red-500'></div>
        </div>}

      <div className='w-[90%] max-w-[600px] lg:max-w-[900px] mx-auto my-4'>
        {pixels && <SelectButtons __onBtnChange={__onBtnChange} refPointStatus={refPointStatus} __clearAll={__clearAll} />}
        
        <div className='gap-2 my-2'>
          <div className='w-full col-span-4 ' ref={(e) => setContainer(e)} onClick={__onImageClick} onMouseMove={__onMouseMove}>
            <Points 
              activeRef={activeRef} 
              container={container} 
              type="point"
              refPoint={refPoint} 
              __scaleFactor={__scaleFactor}
            />
            <div className='h-full w-full relative'>
            {refPointStatus === 'line' && <CanvasLine containerRef={container} setLines={setLines} className="z-50" status='remove'/>}
            {/* <div className='absolute w-full h-full top-0 left-0' style={{ zIndex: refPointStatus ? 1 : 50 }}>
              <DrawRect containerRef={container} disabled={refPointStatus ? true : false } />
            </div> */}
              <ReactCrop crop={crop} onChange={setCrop} onDragEnd={__onDragEnd} disabled={refPointStatus ? true : false } style={{ display: "block" }}>
                {pixels && refPointStatus && !result && <div className='img-zoom-lens' ref={lensRef}></div>}
                <img src={url} alt="" ref={imgRef}  className={`w-full h-full`} style={{ display: result ? "none" : 'block'}}  />
                {result && <img src={`${BASE_URL}/api/${result.image_url}`} key={`image_${Math.floor(Math.random() * 1e5)}`} onError={(e) => { e.src = url }} alt="response pictures" className='w-full h-full' />}
                <Points 
                  activeRef={activeRef} 
                  container={container} 
                  type="line"
                  lines={lines} 
                  __scaleFactor={__scaleFactor}
                />
              </ReactCrop>
                          
            </div>
          </div>
        </div>

        {refPointStatus && !result && 
        <ImageForm 
          onSubmit={__onSumbmit} 
          __scaleFactor={__scaleFactor}
          refPoint={refPoint}
          setRefPoint={setRefPoint}
          setActiveRef={setActiveRef}
          lines={lines} 
          setLines={setLines}
          result={result}
        />}
      {result && <DischargeResult data={result} />}
      {/* {!!result && <div className='flex flex-row justify-center items-center'>
        <button type='button' onClick={__clearAll} className='text-sm px-2 py-1.5 text-white bg-red-500 rounded-md hover:opacity-90 w-[50%] mx-auto'>Clear</button>
      </div>} */}
      </div>
    </section>
  )
}


export const CanvasLine = ({ containerRef, setLines, disabled=false, values={ start: null, stop: null }, className, active=false, status='draw' }) => {
  return (
    <div className={twMerge('absolute top-0 left-0 w-full h-full z-10', className)}>
      <DrawLine 
        active={active} 
        containerRef={containerRef} 
        onChange={(value) => !disabled && setLines((prev) => ([...prev, value])) } 
        values={values} 
        disabled={disabled} 
        drawStatus={status}
      />
    </div>
  )
}

export default ImageContainer