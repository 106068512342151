import React from 'react'
import { twMerge } from 'tailwind-merge'
import { calculateTime } from 'utils/calculateTime'

const Timer = ({ duration, className }) => {
  return (
    <div className={twMerge('', className)}>
      {calculateTime(duration)}
    </div>
  )
}

export default Timer