import React from 'react'
// import { useNavigate } from 'react-router-dom'

const SelectButtons = ({ refPointStatus, __onBtnChange, __clearAll }) => {
  // const navigate = useNavigate()

  return (
    <div className='flex flex-row justify-between items-center gap-3'>
      {/* <button onClick={() => navigate('/')} className='text-sm px-4 py-1.5 text-white bg-gray-400 rounded-md hover:opacity-90'>Back</button> */}
      <div className='flex flex-row justify-center items-center gap-3'>
        <button 
          onClick={() => __onBtnChange('crop')} 
          className={`text-sm px-4 py-1.5 text-white ${refPointStatus === 'crop' ? "bg-green-400" : "bg-red-400"} rounded-md hover:opacity-90`}
          >
          Geo Location
        </button>
        or
        <button 
          onClick={() => __onBtnChange('line')} 
          className={`text-sm px-4 py-1.5 text-white ${refPointStatus === 'line' ? "bg-green-400" : "bg-red-400"} rounded-md hover:opacity-90`}
        >
          Distance
        </button>
      </div>
      

      <button onClick={() => __clearAll()} className='text-sm py-1.5 px-4 text-white bg-red-500 rounded-md hover:opacity-90'>Reset All</button>
  </div>
  )
}

export default SelectButtons