// import GetImagePixel from 'components/GetImagePixel'
import ImageContainer from 'components/SelectImagePixel/ImageContainer'
import { BASE_URL } from 'config'
import React from 'react'
import { useParams } from 'react-router-dom'

const SelectImagePixel = () => {
  const params = useParams()

  return (
    <div >
      {/* <GetImagePixel src={`${BASE_URL}/api/trim-video/${params.url}`} /> */}
      <ImageContainer url={`${BASE_URL}/api/trim-video/${params.url}`} />
      
    </div>
  )
}

export default SelectImagePixel