import React from 'react'
import { CanvasLine } from './ImageContainer'

const Points = ({ activeRef, refPoint, container, __scaleFactor, lines, type='point' }) => {
  if(type === 'point') {
    return (
      Array.isArray(refPoint) && 
      refPoint.map((item, indx) => {
        const { widthScaleFactor, heightScaleFactor } = __scaleFactor()
        
        const left = container.offsetLeft + (item.pixels[0]/widthScaleFactor)
        const top = container.offsetTop + (item.pixels[1]/heightScaleFactor)
        return (
          <div 
            key={indx}
            className={`h-[5px] w-[5px] absolute rounded-full ${activeRef === indx ? "bg-green-500" : "bg-red-500"}`} 
            style={{ left: left-2.5, top: top-2.5, zIndex: 100000 }} 
          />
        )
      })
    )
  }

  if(type === 'line'){
    return (
      Array.isArray(lines) && 
      lines.map((line, indx) => {
        return <CanvasLine containerRef={container} key={indx} active={activeRef === indx} values={line} disabled={true} />
      })
    )
  }
  
  return null;
}

export default Points