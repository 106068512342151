import React from 'react'
import { ImSpinner } from "react-icons/im";
import { twMerge } from 'tailwind-merge';

const Spinner = ({ className, text, isLoading }) => {
  if(!isLoading) return null; 
  return (
    <div className={twMerge("flex flex-col justify-center items-center fixed h-full w-full top-0 left-0 bg-black z-50 bg-opacity-35", className)}>
      <div className='animate-spin'>
        <ImSpinner className='text-3xl text-white' />
      </div>
      {text && <span className='text-sm text-white'>Please Wait</span>}

    </div>
  )
}

export default Spinner