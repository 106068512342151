import React, { useCallback, useEffect, useRef } from 'react'
import { sliderValueToVideoTime } from 'utils/utils'
import "video-react/dist/video-react.css"

// const Video = ({ src, startTime, onPlayerChange= () => {}, onChange= () => {} }) => {
//   const playerRef = useRef(null)
//   const [playerState, setPlayerState] = useState(undefined)

//   useEffect(() => {
//     if(playerState) { onChange(playerState) }
//   }, [onChange, playerState])

//   useEffect(() => {
//     onPlayerChange(playerRef.current)
//     if(playerRef.current) playerRef.current.subscribeToStateChange(setPlayerState)
//   }, [onPlayerChange])

//   return (
//     <div className='video-player'>
//       <Player
//         ref={playerRef}
//         startTime={startTime}
//       >
//         <source src={src} />
//         <BigPlayButton position='center' />
//         <LoadingSpinner />
//         <ControlBar autoHide={false} disableDefaultControls={false}>
//           <PlayToggle />
//         </ControlBar>
//       </Player>
//     </div>
//   )
// }

const Video = ({ src, setStartEnd, range, setDuration }) => {
  const videoRef = useRef(null)
  const timeout = useRef(null)
  // const [toggle, setToggle] = useState(false)

  useEffect(() => {
    if(videoRef.current){
      videoRef.current.load()
    }
  }, [src])

  const __changeRange = useCallback(() => {
    if(!videoRef.current.duration || isNaN(videoRef.current.duration)) { setDuration(0); setStartEnd([0, 0]); return; }

    const [min, max] = range
    const minTime = sliderValueToVideoTime(videoRef.current.duration, min)
    const maxTime = sliderValueToVideoTime(videoRef.current.duration, max)
    
    setDuration(maxTime - minTime)
    setStartEnd([minTime, maxTime])
    videoRef.current.currentTime = minTime
    videoRef.current.play()
    // if (videoRef.current.currentTime < minTime){
    //   videoRef.current.currentTime = minTime          
    // }
    // if(videoRef.current.currentTime > maxTime){
    //   videoRef.current.currentTime = minTime
    // }
  }, [range, setDuration, setStartEnd])

  useEffect(() => {
    if(videoRef.current) setTimeout(__changeRange, 300)
  }, [__changeRange, src])


  const __onPlay = (e) => {
    // setToggle(false)
    if(timeout.current) clearInterval(timeout.current)
    if(!videoRef.current.duration || isNaN(videoRef.current.duration)) { setDuration(0); setStartEnd([0, 0]); return; }

    timeout.current = setInterval(() => {
      const [min, max] = range
      const minTime = sliderValueToVideoTime(videoRef.current.duration, min)
      const maxTime = sliderValueToVideoTime(videoRef.current.duration, max)
      // console.log(videoRef.current.currentTime, minTime, maxTime)
      if (videoRef.current.currentTime < minTime){
        videoRef.current.currentTime = minTime          
      }
      if(videoRef.current.currentTime > maxTime){
        videoRef.current.currentTime = minTime
      }
    }, 800)
  }

  const __onPause = (e) => {
    // setToggle(false)
    if(timeout.current) clearInterval(timeout.current)
  }

  useEffect(() => {
       return () => clearInterval(timeout.current)
  }, [])

  return (
    <div className='w-full h-full relative'>
      <video ref={videoRef} controls={true} className='w-full h-full object-cover' onPlay={__onPlay} onPause={__onPause} loop >
        <source src={src} />
      </video>
    </div>
  )
}

export default Video